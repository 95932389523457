:root {
  --primary-11: #00090B;
  --primary-10: #1B0619;
  --primary-9: #3A0027;
  --primary-8: #5F003B;
  --primary-7: #740046;
  --primary-6: #900055;
  --primary-5: #A51A71;
  --primary-4: #BC5191;
  --primary-3: #CE7FAD;
  --primary-2: #E1B1CC;
  --primary-1: #F4DFEB;


  --primary-color: var(--primary-6); // color used in main actions

  --text-color: black;
  --input-focus-shadow-color: #3A0027; // outer shadow of input on focus - GIVE 20% ALPHA

  --background-color: #f3f3f3;

  --menu-text-color: black;
  --menu-item-active-bg: #E1B1CC; // color of the side menu active ite
  --menu-bg: #F4F9FA; // color of the background of the menu
  --menu-sub-bg: #FFFFFF; // color of the background of the submenu

  --login-background-color: var(--primary-10);

  --header-logo-background: var(--primary-9);
  --header-background: var(--primary-color);
  --header-text-color: #fff;

  --footer-text-color: #fff;
  --footer-color: var(--primary-5);
}

@primary-11: #00090B;
@primary-10: #1B0619;
@primary-9: #3A0027;
@primary-8: #5F003B;
@primary-7: #740046;
@primary-6: #900055;
@primary-5: #A51A71;
@primary-4: #BC5191;
@primary-3: #CE7FAD;
@primary-2: #E1B1CC;
@primary-1: #F4DFEB;

@primary-color: #900055;
@component-background: #ffffff;
@background-color-base: #ffffff;